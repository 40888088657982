import React, {useContext, useEffect } from 'react'

import Slider from 'react-slick'
import scanning from '../../image/ScanningProduct.jpg'
import billing from '../../image/billing.jpg'
import {ReactComponent as SVG} from '../../image/leaves-svgrepo-com.svg'
import { TrolleyContext } from "../../TrolleyContext";
import {useNavigate} from 'react-router-dom'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Marquee from 'react-fast-marquee';
import base_img from '../../image/products/new_img.jpg'

import { Card, CardActions, CardContent ,CardMedia ,ImageList, ImageListItem, ImageListItemBar} from '@mui/material';
import title_card_img from '../../image/products/shopping.jpg';
import '../../assets/css/Home_page.css';
import InfoIcon from '@mui/icons-material/Info';


// asset images
import product1 from '../../assets/img/web_page/product1.jpg';
import product2 from '../../assets/img/web_page/product2.jpg';
import product3 from '../../assets/img/web_page/product3.jpg';
import product4 from '../../assets/img/web_page/product4.jpg';
import product5 from '../../assets/img/web_page/product5.jpeg';
import product6 from '../../assets/img/web_page/product6.jpeg';
import product7 from '../../assets/img/web_page/product7.jpeg';
import product8 from '../../assets/img/web_page/product8.jpg';
import product9 from '../../assets/img/web_page/product9.jpg';
import product10 from '../../assets/img/web_page/product10.jpg';
import product11 from '../../assets/img/web_page/product11.jpeg';
import product12 from '../../assets/img/web_page/product12.jpeg';
import product13 from '../../assets/img/web_page/product13.jpg';
import product14 from '../../assets/img/web_page/product14.jpg';
import product15 from '../../assets/img/web_page/product15.jpg';
import product16 from '../../assets/img/web_page/product16.jpg';
import location_img from '../../assets/img/web_page/sit_img.png';
import footer_img1 from '../../assets/img/web_page/footer_img.jpg';

// social media icons
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail';

const InitialPage = () =>{

    const navigate = useNavigate()
    const {updatedData} = useContext(TrolleyContext)

    useEffect(() => {
        if(updatedData.length > 0) {
            navigate('/cart')
        }
    },[updatedData])

   
    const image_list_item =[{
        img:product1,
        title:'GROCERY & STAPLES',
    },{
        img:product2,
        title:'DAILY ESSENTIALS',
    },{
        img:product3,
        title:'DAIRY & FROZEN',
    },{
        img:product4,
        title:'DMART BRANDS',
    },{
        img:product5,
        title:'HOME & PERSONAL CARE',
    },{
        img:product6,
        title:'BET & BATH',
    },{
        img:product7,
        title:'HOME APPLIANCES',
    },{
        img:product8,
        title:'CROCKERY',
    },{
        img:product9,
        title:'FOOTWARE',
    },
    {
        img:product10,
        title:'LUGGAGE',
    },{
        img:product11,
        title:'TOYS & GAMES',
    },{
        img:product12,
        title:'PLASTIC CONTAINER',
    },{
        img:product13,
        title:'KID`S APPAREL',
    },{
        img:product14,
        title:'WOMEN`S  APPAREL',
    },{
        img:product15,
        title:'MEN`S APPAREL',
    },{
        img:product16,
        title:'FRUITS & VEGETABLES',
    }];

   
 
  

    return (
     
        <>
            <Box sx={{ flexGrow: 1 }} >
                <AppBar position="fixed"  style={{backgroundColor:"white"}}>
                    <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        // color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                   
                    <Typography   variant="h6" component="div"   sx={{ justifyContent:"flex-end",color:'#009688',fontWeight:'bold' }}>Smart Trolley</Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <div style={{marginTop:'3.5rem'}}>
                <Marquee direction='left'  speed={40} gradient={false} style={{color:'white',backgroundColor:'#4db6ac',fontsize:'15px',padding:'1rem'}}>
                    Dear Customers - Our offers / promotions and customer care contact details are published on our websites ( www.smart-trolley.in  and smart-trolley.in ), Smart Trolley Ready App and at our Stores. Please do not call any unknown numbers or click on any unknown links or similar looking links. Please check the website name carefully. These could be potential phishing websites.       
                </Marquee>
                <Box component="img" sx={{height:'100%',width:'100%',Padding:'0px !important',marginBottom:'10px'}} src={base_img} alt="Base image"></Box>

                <div className='' style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:'10px'}}>
                    <h3 style={{borderBottom:'2px solid #00695c',paddingBottom:'5px'}}>QUICK LINKS</h3>
                </div>

                <Card sx={{ Width:'100%',margin:'10px'}}>
                    <CardMedia
                        sx={{ height: 140 }}
                        image={title_card_img}
                        title="green iguana"
                    />
                    <CardContent>
                        <Typography sx={{fontSize:'1rem',fontWeight:'bold'}}  component="div">New Suppliers</Typography>
                        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>Would you like to showcase your products to us?</Typography>
                    </CardContent>
                    <CardActions className='title_card_responsive'>
                        <Button variant="contained" color='success' className='card_btn_res'>Pre-Book</Button>
                        <Button variant="contained" color='success' className='card_btn_res'>Home Delivery</Button>
                    </CardActions>
                </Card>

                <div className='' style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:'10px',marginTop:'1.5rem'}}>
                    <h3 style={{borderBottom:'2px solid #00695c',paddingBottom:'5px'}}>STORE CATEGORIES</h3>
                </div>

                <ImageList style={{ width: '100%',height:'100%',padding:'1rem'}}>
                    {image_list_item.map((item)=>(
                        <ImageListItem key={1}>
                        <img
                            srcSet={item.img}
                            src={item.img}
                            alt="img"
                            loading="lazy"
                        />
                        <ImageListItemBar
                            title={item.title}
                            actionIcon={
                            <IconButton
                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                aria-label={`info about `}
                            >
                                <InfoIcon />
                            </IconButton>
                            }
                        />
                        </ImageListItem>

                    ))}
                                      
                </ImageList>


                <div  className='footer_container'>
                    <div className='footer_title'>
                        <span sx={{color:'#bdbdbd',fontSize:'1rem'}}>Get connected with us on social networks:</span>
                        <div className='icons_div'>
                            <FacebookIcon sx={{fontSize:'1.5rem'}}/>
                            <TwitterIcon sx={{fontSize:'1.5rem'}}/>
                            <InstagramIcon sx={{fontSize:'1.5rem'}}/>
                            <WhatsAppIcon sx={{fontSize:'1.5rem'}}/>
                            <MailIcon sx={{fontSize:'1.5rem'}} />
                        </div>
                    </div>

                    {/*
                    <div className='footer_content'>
                        <div className='footer_content_div'>
                            <h3 sx={{color:'#bdbdbd',marginBottom:'1rem'}}>COMPANY NAME</h3>
                            <span>Quantanics Tech serve Pvt Ltd</span>
                            <span>104/A street</span>
                            <span>Thirupparankundream</span><br />
                            <span>madurai-625005</span>
                        </div>

                        <div className='footer_content_div'>
                            <h3 sx={{color:'#bdbdbd',marginBottom:'1rem'}}>LOCATION</h3>
                            <img src={location_img} style={{height:'150px'}} />
                            
                        </div>

                        <div className='footer_content_div'>
                            <h3 sx={{color:'#bdbdbd',marginBottom:'1rem'}}>ABOUT US</h3>
                            <span>At Smart Trolley, we believe in delivering fresh, quality products right to your doorstep. With a wide range of groceries, household items, and local produce, we are dedicated to making your shopping experience easy, convenient, and enjoyable.</span>
                        </div>
                        
                    </div>

                    <div className='footer_copy_right'>
                        © 2024 Copyright : <strong>Quantanics Tech Serve Pvt Ltd</strong>
                    </div>
                    */}
                </div>

            </div>
           
        </>
    )
}
export default InitialPage;